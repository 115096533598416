<template>
  <div>
    <select-holder
      :loading="loading"
      :placeholder="$t('components.multi_items_select.select.placeholder')"
      :shown-items="shownItems"
      @show-modal="handleModalShow"
    />

    <th-modal
      name="branches-select"
      height="800px"
      width="1200px"
      :title="$t('pages.customers.edit.form.properties.branches.select.title')"
    >
      <multi-location-select
        v-model="selectedBranches"
        :filters="filtersList"
      />
      <template #footer>
        <actions @cancel="handleCancel" @save="handleSave" />
      </template>
    </th-modal>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pAll from 'p-all'
import typeOf from 'just-typeof'
import compare from 'just-compare'
import MultiLocationSelect from '@/components/select/multi-location-select'
import SelectHolder from './components/select-holder'
import Actions from './components/actions'

export default {
  components: {
    MultiLocationSelect,
    SelectHolder,
    Actions
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedBranches: [],
      shownItems: [],
      loading: false,
      filtersList: [
        {
          name: 'name',
          type: 'remote-search-select',
          label: this.$t('common.headers.name.title'),
          resource: 'branches',
          optionsValue: 'name',
          appendToBody: true,
          computeName: (branch) => branch.name,
          modifyQuery: (q) => ({
            q,
            fields: ['name']
          })
        },
        {
          name: 'branch_number',
          type: 'remote-search-select',
          label: this.$t('pages.branches.all.headers.branch_number'),
          resource: 'branches',
          optionsValue: 'branch_number',
          appendToBody: true,
          computeName: (branch) => branch.branch_number,
          modifyQuery: (q) => ({
            q,
            fields: ['branch_number']
          })
        },
        {
          name: 'street',
          type: 'remote-search-select',
          label: this.$t('common.headers.street.title'),
          options: [],
          resource: 'branches',
          filterable: true,
          doInitialFetch: false,
          appendToBody: true,
          noRepeatKey: 'addresses.street',
          computeName: (branch, q) =>
            this.getAddressesProperty(branch, q, 'street'),
          expandOriginalData: (branch, q) =>
            this.getAddressesProperty(branch, q, 'street'),
          modifyQuery: (searchTerm, handlerName) => {
            if (handlerName !== 'search') return { street: searchTerm }
            return {
              q: searchTerm,
              fields: ['addresses.street']
            }
          }
        },
        {
          name: 'postal_code',
          type: 'remote-search-select',
          label: this.$t('pages.branches.all.headers.postal_code'),
          options: [],
          resource: 'branches',
          filterable: true,
          doInitialFetch: false,
          appendToBody: true,
          noRepeatKey: 'addresses.postal_code',
          computeName: (branch, q) =>
            this.getAddressesProperty(branch, q, 'postal_code'),
          expandOriginalData: (branch, q) =>
            this.getAddressesProperty(branch, q, 'postal_code'),
          modifyQuery: (searchTerm, handlerName) => {
            if (handlerName !== 'search') return { postal_code: searchTerm }
            return {
              q: searchTerm,
              fields: ['addresses.postal_code']
            }
          }
        },
        {
          name: 'city',
          type: 'remote-search-select',
          label: this.$t('common.headers.city.title'),
          options: [],
          resource: 'branches',
          filterable: true,
          doInitialFetch: false,
          appendToBody: true,
          noRepeatKey: 'addresses.locality',
          computeName: (branch, q) =>
            this.getAddressesProperty(branch, q, 'locality'),
          expandOriginalData: (branch, q) =>
            this.getAddressesProperty(branch, q, 'locality'),
          modifyQuery: (searchTerm, handlerName) => {
            if (handlerName !== 'search') return { city: searchTerm }
            return {
              q: searchTerm,
              fields: ['addresses.locality']
            }
          }
        }
      ]
    }
  },
  watch: {
    modelValue(newItems, oldItems) {
      if (Array.isArray(newItems) && !compare(newItems, oldItems)) {
        this.fetchSelectedItemsData()
      }
    }
  },
  methods: {
    parsePhoneNumbers(branch, q) {
      if (typeOf(branch.phonenumbers) === 'string') return branch.phonenumbers
      if (!q) return

      if (typeOf(branch.phonenumbers) === 'object') {
        return Object.values(branch.phonenumbers).find((n) => n.includes(q))
      }
    },
    handleCancel() {
      this.$thModal.hide('branches-select')
    },
    handleSave() {
      this.$emit('update:modelValue', this.selectedBranches)
      this.$thModal.hide('branches-select')
    },
    handleModalShow() {
      this.selectedBranches = [...(this.modelValue || [])]
      this.$thModal.show('branches-select')
    },
    getAddressesProperty(branch, q = '', prop) {
      const b = (branch.addresses || []).find((address) =>
        address[prop].toLowerCase().includes(q.toLowerCase())
      )
      if (b) return b[prop]
      return '-'
    },
    async fetchSelectedItemsData() {
      try {
        this.loading = true
        const inst = th.branches()
        const actions = this.modelValue.map((item) => () => inst.get(item))
        const res = await pAll(actions)
        const itemsData = res.map((item) => item.data)
        this.shownItems = itemsData
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
