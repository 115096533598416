<template>
  <group-items-select
    v-model="localLocations"
    resource="branchesV1"
    show-search-filter
    :filters="filters"
    :normalise-keys="locationsNormaliseKeys"
    :title="$t('components.available_in.locations.label')"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import GroupItemsSelect from '@/components/group-items-select'

export default {
  components: {
    GroupItemsSelect
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      userAssignedLocations: 'Auth/getUserAssignedLocations'
    }),
    localLocations: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },
  methods: {
    locationsNormaliseKeys(location) {
      return {
        ...location,
        title_main: this.$formatBranch(location),
        disabled: !Array.isArray(this.userAssignedLocations)
          ? false
          : !this.userAssignedLocations.includes(location.id)
      }
    }
  }
}
</script>
